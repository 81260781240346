import Space from '@/components/atoms/Space'
import SelectorGroup from '@/components/molecules/SelectorGroup'
import {withLayout} from '@/components/template/Layout'
import {siteActivityApi} from '@/ghgApi'
import useAnalysis from '@/hooks/useAnalysis'
import useSiteManagement from '@/hooks/useSiteManagement'
import {
    ActivityWithEmissionFactor,
    CalculationMethodTreeNode,
    Category,
    EmissionFactor,
    Scope,
    Site
} from '@/openapi/api'
import useStore from '@/zustand/sotre'
import {makeStyles} from '@material-ui/core'
import * as FileSaver from 'file-saver'
import React, {useEffect, useMemo, useState} from 'react'
import YearSelector from "@/components/template/Analysis/Selector/YearSelector";
import PrimaryButton from "@/components/atoms/Button/PrimaryButton";
import * as Encoding from "encoding-japanese";
import Papa from "papaparse";
import {InputTreeLeafNode, InputTreeNode, InputTreeNodeEvents} from "@/utils/tree";
import {InputDate} from "@/zustand/slice/inputSlice";
import {MenuItemProps} from '@/components/atoms/SelectField'
import { ALL_SITES } from '@/constant/sites'


// ====================================================================================
// Overview
// ====================================================================================
type EmissionFactorForDownload = EmissionFactor & {
    type: InputTreeNode['name']
    calculationMethod: InputTreeLeafNode['name']
    categoryId: Category['id']
    emissionFactorTableId: number
    quantity: number
    memo: string
    scope: Scope | undefined
}

interface ActivityWithEfAndSiteId extends ActivityWithEmissionFactor {
    siteId: number;
}

const useStyles = makeStyles({
    operationTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    selectGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 20,
        fontWeight: 300,
        fontSize: 16,
        marginTop: '20px',
    },
})

const OverviewInner = () => {
    const {
        storeState,
        analysisState,
        scopeData,
    } = useStore()
    const {
        site,
        siteOptions,
        selectSite,
        childSites,
        bottomSites,
        setSiteDropDown,
        handleSiteChange,
        setSelectSite
    } = useSiteManagement()
    const classes = useStyles()
    const { setAnalysisDate } = useAnalysis()
    const [trees, setTrees] = useState<InputTreeNode[]>([])
    const [ready, setReady] = useState(false)
    const [readyCount, setReadyCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [activities, setActivities] = useState<ActivityWithEfAndSiteId[] | undefined>(undefined)
    const [hasActivities, setHasActivities] = useState(false)

    const readyToFetchData = useMemo(() => {
        return site !== null
            && site?.id !== null
            && analysisState.date.year !== null
            && childSites !== undefined
            && bottomSites !== undefined
    }, [site?.id, analysisState.date.year, childSites, bottomSites ])

    // 組織の選択した年度を月毎に取得
    const oneYearDates = useMemo(() => {
        let dates: InputDate[] = []
        if (storeState.organization?.startMonth) {
            for (let i = 0; i < 12; i++) {
                const month = storeState.organization.startMonth + i
                if (month > 12) {
                    dates.push({year: analysisState.date.year + 1, month: month - 12})
                } else {
                    dates.push({year: analysisState.date.year, month: month})
                }
            }
        }
        return dates
    }, [storeState.organization, analysisState.date])

    const markReady = ((e: CustomEvent) => {
        if (!e.detail.error) {
            setReadyCount((preCount) => {
                return preCount + 1
            })
        } else console.warn(e.detail.error)
    }) as EventListener

    //========================================================
    //                       useEffect
    //========================================================
    useEffect(() => {
        const sites = storeState.sites.filter(s => s.status === 1)
        setSiteDropDown(sites)
    }, [])

    // CSVダウンロードボタン活性を制御
    useEffect(() => {
        const hasEf = trees.every((t) => t.hasEmissionFactors)
        setReady(hasEf && !!activities)
        // TODO: 検証環境でのデバッグ用。本番リリース前に消す
        console.log("ready state", {treesLength: trees.length, readyCount})
    }, [readyCount, activities]);

    // loadingを制御
    useEffect(() => {
        setLoading(!ready)
    }, [ready])


    useEffect(() => {
        setHasActivities(!!activities && activities.length > 0)
    }, [activities])

    // CSV出力するデータを取得
    useEffect(() => {
        if (!readyToFetchData) return
        // 初期化
        setReady(false)
        setReadyCount(0)
        setActivities(undefined)
        const fetchData = async () => {
            try {
                let allActivities: ActivityWithEfAndSiteId[] = []
                for (const thisSite of childSites!) {
                    try {
                        const response = await siteActivityApi.getSiteYearActivityWithEmissionFactor(
                            thisSite.id,
                            analysisState.date.year
                        )
                        if (response?.data?.data) {
                            const activitiesWithSiteId = response.data.data.map(activity => ({
                                ...activity,
                                siteId: thisSite.id
                            }))
                            allActivities = [...allActivities, ...activitiesWithSiteId]
                        }
                    } catch (err) {
                        console.warn('SiteActivityApi.getSiteYearActivity:', err)
                    }
                }
                setActivities(allActivities)
            } catch(err) {
                console.warn('error at fetchData:', err)
            }
        }

            fetchData()
    }, [readyToFetchData, analysisState.date.year, selectSite]);

    // ツリーを形成
    useEffect(() => {
        let treeData: InputTreeNode[] = []
        const time = analysisState.date as InputDate || undefined
        // 全カテゴリのInputTreeNode作成
        if (scopeData.length == 3 && scopeData[2].categories) {
            // Scope1の処理
            treeData.push(new InputTreeNode(scopeData[0].categories[0].calculationMethodTree as CalculationMethodTreeNode, time, scopeData[0]))
            treeData.push(new InputTreeNode(scopeData[0].categories[0].calculationMethodTree as CalculationMethodTreeNode, { ...time, year: time.year - 1 }, scopeData[0]))
            // Scope2の処理
            treeData.push(new InputTreeNode(scopeData[1].categories[0].calculationMethodTree as CalculationMethodTreeNode, time, scopeData[1]))
            treeData.push(new InputTreeNode(scopeData[1].categories[0].calculationMethodTree as CalculationMethodTreeNode, { ...time, year: time.year - 1 }, scopeData[1]))
            // Scope3の処理
            scopeData[2].categories.forEach((c) => {
                treeData.push(new InputTreeNode(c.calculationMethodTree as CalculationMethodTreeNode, time, scopeData[2]))
                treeData.push(new InputTreeNode(c.calculationMethodTree as CalculationMethodTreeNode, { ...time, year: time.year - 1 }, scopeData[2]))
            })
        }
        treeData.forEach((t) => {
            t.target.addEventListener(InputTreeNodeEvents.EMISSION_FACTORS_GET, markReady)
        })
        setTrees(treeData)

        return () => {
            treeData.forEach((t) => {
                t.target.removeEventListener(InputTreeNodeEvents.EMISSION_FACTORS_GET, markReady)
            })
        }
    }, [])

    //========================================================
    //                       関数とか
    //========================================================

    const onYearSelected = (year: number) => {
        setAnalysisDate('year', year)
    }

    // 排出係数を再帰的に取得
    const getAllEFFactor = (tree: InputTreeNode) => {
        const allEFFactor: EmissionFactorForDownload[][] = []
        const flattenEf = (inputTreeNode: InputTreeNode[]) => {
            inputTreeNode.every((child) => {
                if (child.children) {
                    flattenEf(child.children)
                    return true
                } else if (child.emissionFactors) {
                    // 葉ノード（排出係数を持つノード）の場合、配列に追加
                    const eFforDownload = child.emissionFactors.map((eF) => ({
                        ...eF,
                        type: child.nameBelowRoot,
                        calculationMethod: child.name,
                        categoryId: child.id,
                        quantity: NaN,
                        emissionFactorTableId: child.emissionFactorTableId,
                        memo: '',
                        scope: child.scope,
                    })) as EmissionFactorForDownload[]
                    allEFFactor.push(eFforDownload)
                    return true
                }
            })
        }
        if (tree.children) flattenEf(tree.children)
        const allEmissionFactors = allEFFactor.flat()
        return allEmissionFactors
    }

    const handleCsvDownload = () => {
        if (trees.length && site?.id !== null) {

            setLoading(true)

            // データ準備中UIをブロッキングしない
            // TODO: データが多いのでCHUNKに分けたりしたい
            setTimeout(() => {
                try {
                    const allEFFactor: EmissionFactorForDownload[][] = []
                    trees.forEach((t) => {
                        const data = getAllEFFactor(t)
                        allEFFactor.push(data)
                    })

                    const csvTextDetail = emissionFactorsToCsvContent(allEFFactor.flat())

                    // CSVファイル保存処理
                    const saveFile = (csvText: string) => {
                        const unicodeList = []
                        for (let i = 0; i < csvText.length; i += 1) {
                            unicodeList.push(csvText.charCodeAt(i))
                        }
                        // https://qiita.com/fumihiko-hidaka/items/1fb8933072db76214079
                        // 変換処理の実施
                        const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE')
                        const uInt8List = new Uint8Array(shiftJisCodeList)
                        const writeData = new Blob([uInt8List], {
                            type: 'text/csv;charset=SHIFT_JIS',
                        })

                        // ダウンロード実行
                        const fileName = `scope-all-detail.csv`
                        FileSaver.saveAs(writeData, fileName)
                    }

                    if (!!csvTextDetail) saveFile(csvTextDetail)
                } catch(error) {
                    console.warn('Error generating CSV:', error)
                } finally {
                    setLoading(false)
                }
            }, 100)
        }

        function emissionFactorsToCsvContent(emissionFactors: Array<EmissionFactorForDownload>) {
            if (activities == null || undefined) return ""

            const csvTextDetail: any[] = []

            // 年度の境界日（4月1日）を設定
            const year = oneYearDates[0].year
            const month = oneYearDates[0].month.toString().padStart(2, '0')
            const boundaryDate = new Date(`${year}-${month}-01T00:00:00+09:00`)

            // 4月1日より前を前年度、以降を今年度とし活動量を振り分け
            const previousYearActivities = activities.filter((a) => {
                const activityDate = new Date(a.month!)
                return activityDate < boundaryDate
            })
            const currentYearActivities = activities.filter((a) => {
                const activityDate = new Date(a.month!)
                return activityDate >= boundaryDate
            })

            // 各月のデータを処理
            oneYearDates.forEach((date) => {
                if (!childSites || !bottomSites) return
                // 処理対象の月が年度境界日より前なら前年度データ、後なら今年度データを使用
                const targetActivities = new Date(`${date.year}-${date.month.toString().padStart(2, '0') }-01T00:00:00+09:00`) < boundaryDate
                    ? previousYearActivities
                    : currentYearActivities

                const formattedDate = `${date.year}-${date.month}`

                // mapを作成
                //      活動がない拠点、年月の組み合わせも出力する必要があるため
                //      キー：出力する拠点、年月、活動量があるcategoryEmissionFactorTableIdとemissionFactorIdの組み合わせ
                const generateCombinationMap = (
                    map: Map<string, ActivityWithEfAndSiteId>,
                    sites: MenuItemProps[]
                ) => {
                    sites.forEach((thisSite) => {
                        targetActivities.forEach((a) => {
                            const key = `${formattedDate}-${thisSite.id}-${a.categoryEmissionFactorTableId}-${a.emissionFactorId}`
                            if (!map.has(key)) {
                                map.set(key, {
                                    ...a,
                                    siteId: thisSite.id,
                                    name: thisSite.value
                                })
                            }
                        })
                    })
                    return map
                }

                // 詳細用のMapを作成
                const uniqueActivityMap = new Map<string, ActivityWithEfAndSiteId>()
                const siteForDetail = bottomSites.map(s => {
                    return {id: s.id, value: s.name}
                })
                generateCombinationMap(
                    uniqueActivityMap,
                    siteForDetail
                )

                // CSVデータ１行分を作成し配列にpushする
                const generateCsvLine = (
                    uniqueActivityMap: Map<string, ActivityWithEfAndSiteId>,
                    targetActivities: ActivityWithEfAndSiteId[],
                    csvText: any[],
                ) => {
                    const uniqueActivities = Array.from(uniqueActivityMap.values())
                    uniqueActivities.forEach((ua) => {
                        // 同じ年月、カテゴリ、排出係数、拠点の活動量データを探す
                        let targetActivity = undefined
                        targetActivity = targetActivities.find((ta) => {
                            const activityDate = new Date(ta.month!)
                            const activityYear = activityDate.getFullYear()
                            const activityMonth = activityDate.getMonth() + 1
                            return activityYear === date.year
                                && activityMonth === date.month
                                && ta.categoryEmissionFactorTableId === ua.categoryEmissionFactorTableId
                                && ta.emissionFactorId === ua.emissionFactorId
                                && ta.siteId === ua.siteId
                        })

                        const emissionFactor = emissionFactors.find((ef) => {
                            return  ef.categoryId === ua.categoryEmissionFactorTableId &&
                                    ef.id === ua.emissionFactorId
                        })

                        // 出力する各列の値
                        const activitySite = storeState.sites.find(s =>s.id === ua.siteId)
                        let siteName: string | undefined = activitySite?.name || ''
                        let quantity: number | undefined = 0
                        let value:string | undefined = '0'

                        if (targetActivity) {
                            quantity = targetActivity.quantity
                            if (!quantity) {
                                quantity = undefined
                                return
                            }
                            value = emissionFactor? (quantity * emissionFactor.value).toFixed(3) : undefined
                        } else {
                            quantity = undefined
                        }

                        if (ua.siteId === 0) {
                            siteName = ALL_SITES.value
                        } else {
                            siteName = activitySite?.name
                        }

                        // 詳細CSV第1階層〜第5階層の値
                        const hierarchy = buildSiteHierarchy(activitySite)

                        csvText.push({
                            第1階層: hierarchy.level1,
                            第2階層: hierarchy.level2,
                            第3階層: hierarchy.level3,
                            第4階層: hierarchy.level4,
                            第5階層: hierarchy.level5,
                            年: date.year,
                            月: date.month,
                            スコープ: emissionFactor?.scope?.name,
                            分類: emissionFactor?.type,
                            算定方法: emissionFactor?.calculationMethod,
                            入力項目: emissionFactor?.name,
                            活動量: quantity,
                            単位: emissionFactor?.unit,
                            算定排出量: value,
                            係数: emissionFactor?.value,
                            '排出係数ID(編集禁止)': ua.emissionFactorId,
                            'カテゴリーID(編集禁止)': ua.categoryEmissionFactorTableId,
                            '拠点ID(編集禁止)': ua.siteId === 0 ? ALL_SITES.id : activitySite?.id,
                            メモ: ua?.memo != null ? ua.memo.replace(/\n/g, "\\n") : ''
                        })
                        
                    })
                }

                generateCsvLine(uniqueActivityMap, targetActivities, csvTextDetail)
            })

            return Papa.unparse(csvTextDetail)
        }

        function buildSiteHierarchy(site: Site| undefined) {
            const hierarchy = {
                level1: '',
                level2: '',
                level3: '',
                level4: '',
                level5: '',
            }

            const sitePath = []
            while(site) {
                sitePath.unshift(site)
                if (!site.parentSiteId) break
                site = storeState.sites.find(s => s.id === site!.parentSiteId)
            }

            if (sitePath.length === 5) {
                hierarchy.level1 = sitePath[0].name
                hierarchy.level2 = sitePath[1].name
                hierarchy.level3 = sitePath[2].name
                hierarchy.level4 = sitePath[3].name
                hierarchy.level5 = sitePath[4].name
            }
            if (sitePath.length === 4) {
                hierarchy.level1 = sitePath[0].name
                hierarchy.level2 = sitePath[1].name
                hierarchy.level3 = sitePath[2].name
                hierarchy.level4 = sitePath[3].name
            }
            if (sitePath.length === 3) {
                hierarchy.level1 = sitePath[0].name
                hierarchy.level2 = sitePath[1].name
                hierarchy.level3 = sitePath[2].name
            }
            if (sitePath.length === 2) {
                hierarchy.level1 = sitePath[0].name
                hierarchy.level2 = sitePath[1].name
            }
            if (sitePath.length === 1) {
                hierarchy.level1 = sitePath[0].name
            }

            return hierarchy
        }

    }

    return (
        <main>
            <title>データ入力｜Emission View</title>
            <SelectorGroup
                value={site?.id ?? ALL_SITES.id}
                options={siteOptions}
                onChange={handleSiteChange}
                setSelectSite={setSelectSite}
            />

            <Space height={15}/>
            <div className={classes.operationTitle}>CSVの一括ダウンロード</div>
            <Space height={15}/>
            {(!loading && !hasActivities) && (
                <div>活動量が登録されていません</div>
            )}
            {loading && (
                <div>データを準備中です...</div>
            )}
            <div className={classes.selectGroup}>
                <div style={{width: 224, minWidth: 224}}>
                    <YearSelector size="sm" onYearSelected={onYearSelected} year={analysisState.date.year}/>
                </div>
                <PrimaryButton
                width={180}
                height={35}
                fontSize={14}
                onClick={handleCsvDownload}
                disabled={!ready || !hasActivities || loading}
                >
                CSVダウンロード
                </PrimaryButton>
            </div>
        </main>
    )
}

const Overview = () => <OverviewInner/>

export default withLayout(Overview)
